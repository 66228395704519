import { ReactComponent as Error } from '@svg/error.svg';
import { useTranslation } from "react-i18next";

/* styles */
import style from "@styles/pdp/SizeAlert.module.scss"

export default function SizeAlert() {
    const { t } = useTranslation("translation");

    return <div className={style.alert}>
        <div className={style.alertContent}>
            <Error />
            <span>{t('pdp.PLEASE-SELECT-SIZE')}</span>
        </div>
    </div>
}