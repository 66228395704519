export const getPublicBundleList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    params.orderBy = 'cross_order'
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/bundles?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}

export const getPublicBundleGroupList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    params.orderBy = 'cross_order'
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/bundle-group?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}

export const getPublicCollectionGroupList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/collection-group?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}

const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const getHeaders = () => {
    const locale = document.querySelector('[data-view-id]').getAttribute('data-view-id')
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    headers.append("siteId", 'TNF-' + locale.split('_')[1]);
    headers.append("locale", locale);
    headers.append("channel", "ECOMM");
    headers.append("source", "ECOM15");
    headers.append("region", "EMEA");
    headers.append("brand", "TNF");
    headers.append("x-transaction-id", generateUUID());


    headers.append("Authorization", "Basic " + btoa("ecom_vf:lW29ZxO9lyFr"));

    return headers
}

export const getProduct = async (productId) => {
    const response = await fetch("/api/products/v2/products/" + encodeURIComponent(productId) + "/details", {
        headers: getHeaders(),
    })

    return await response.json()
}

export const addToBasket = async (products) => {
    const cartProducts = Object.keys(products).map(sku => {
        return products[sku].catId
    })

    const addToCartEvent = new CustomEvent('add-to-cart', { detail: cartProducts })

    // Get the bundle container element
    const element = document.getElementById('root')
    // Dispatch the event on the finder container
    element.dispatchEvent(addToCartEvent)

}